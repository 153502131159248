import { Collection } from 'firestorter';

import BaseDocument from '../../Model/BaseDocument';
import InternalAssignment from '../../Model/InternalAssignment';
import { AssignmentRole } from '../../utils/internalAssignments';
import Collections from '../../utils/collections';
import LeadHistory from './LeadHistory';

/**
 * The state of a lead.
 */
const LeadState = {
  NEW: 'NEW',
  CONTACTED: 'CONTACTED',
  IN_DIALOGUE: 'IN_DIALOGUE',
  FIRST_MEETING: 'FIRST_MEETING',
  OFFER_SENT: 'OFFER_SENT',
  ON_HOLD: 'ON_HOLD',
  LOST: 'LOST',
  PENDING: 'PENDING',
  WON: 'WON',
  IS_CONTACTED: 'IS_CONTACTED',
  UNQUALIFIED: 'UNQUALIFIED',
  CALENDLY_SCHEDULED: 'CALENDLY_SCHEDULED',
};

/**
 * The sales stage of a lead.
 */
const SalesStage = {
  NEW_LEAD: 'NEW_LEAD',
  INVALID_CONTACT: 'INVALID_CONTACT',
  FIRST_MESSAGE: 'FIRST_MESSAGE',
  SECOND_MESSAGE: 'SECOND_MESSAGE',
  THIRD_MESSAGE: 'THIRD_MESSAGE',
  IN_DIALOGUE: 'IN_DIALOGUE',
  UNQUALIFIED: 'UNQUALIFIED',
  LOST: 'LOST',
  WON: 'WON',
  PENDING: 'PENDING',
  CALENDLY_SCHEDULED: 'CALENDLY_SCHEDULED',
  DUPLICATE: 'DUPLICATE',
};

const FinalStatusSalesStages = [
  SalesStage.LOST,
  SalesStage.WON,
  SalesStage.UNQUALIFIED,
  SalesStage.PENDING,
];

const InitialLeadState = [
  LeadState.NEW,
  LeadState.CONTACTED,
  LeadState.IN_DIALOGUE,
  LeadState.FIRST_MEETING,
  LeadState.OFFER_SENT,
  LeadState.ON_HOLD,
  LeadState.PENDING,
  LeadState.IS_CONTACTED,
  LeadState.CALENDLY_SCHEDULED,
];

const AllLeadStates = Object.values(LeadState);
const AllSalesStages = Object.values(SalesStage);

/**
 * The contact provider for a lead.
 */
const ContactProvider = {
  iMessage: 'iMessage',
  WhatsApp: 'WhatsApp',
  EMAIL: 'Email',
  SMS: 'SMS',
  ANY: 'ANY',
};

const LocalContactProvider = {
  [ContactProvider.iMessage]: ContactProvider.iMessage,
  [ContactProvider.WhatsApp]: ContactProvider.WhatsApp,
};

const LeadHistoryType = {
  NOTE: 'NOTE',
  STATE_CHANGE: 'STATE_CHANGE',
  SALES_STAGE_CHANGE: 'SALES_STAGE_CHANGE',
  CONTACT: 'CONTACT',
  MSG: 'MSG',
  MSG_SENT: 'MSG_SENT',
  CALL: 'CALL',
  CALENDLY_SCHEDULED: 'CALENDLY_SCHEDULED',
  DIRECT_PURCHASE_LINK_SENT: 'DIRECT_PURCHASE_LINK_SENT',
  SENT_TO_RESCHEDULE: 'SENT_TO_RESCHEDULE',
  RESCHEDULED_MANUALLY: 'RESCHEDULED_MANUALLY',
  RESPONSE: 'RESPONSE',
  CALL_PLACED: 'CALL_PLACED',
  NO_SHOW: 'NO_SHOW',
};

/**
 * Class representing a lead.
 *
 * @class Lead
 * @extends BaseDocument
 */
class Lead extends BaseDocument {
  constructor(id, opts) {
    super(`${Collections.LEADS}/${id}`, opts);
  }

  /**
   * Get the first name.
   * @return {string}
   */
  get firstName() {
    return this.data.firstName || '';
  }

  /**
   * Get the last name.
   * @return {string}
   */
  get lastName() {
    return this.data.lastName || '';
  }

  /**
   * Get the full name.
   * @return {string}
   */
  get name() {
    const name = `${this.firstName.trim()} ${this.lastName.trim()}`;
    return name || this.data.name;
  }

  /**
   * Get the email.
   * @return {string}
   */
  get email() {
    return this.data.email;
  }

  /**
   * Get the gender.
   * @return {string}
   */
  get gender() {
    return this.data.gender;
  }

  /**
   * Get the lead submitted date and time in 'YYYY-MM-DD HH:mm:ss' format
   * @return {string}
   */
  get submitDate() {
    return this.data.submitDate;
  }

  /**
   * Get anonymous user id for tracking purposes in Paperform integration
   * @return {string}
   */
  get anonid() {
    return this.data.anonid;
  }

  /**
   * Get aggregating questions and answers for the questions in Paperform integration
   * @return {string}
   */
  get responses() {
    return this.data.responses;
  }

  /**
   * Get the lead submitted answers
   * @return {Array}
   */
  get answers() {
    return this.data.answers;
  }

  /**
   * Get the state of the lead
   * @return {string}
   */
  get state() {
    return this.data.state;
  }

  /**
   * Get the sales stage of the lead
   * @return {string}
   */
  get salesStage() {
    return this.data.salesStage;
  }

  /**
   * Get the name of assigned sendblue number for the lead
   */
  get sendBlueName() {
    return this.data.sendBlueName;
  }

  /**
   * Get the lead submitted country
   * @return {string}
   */
  get country() {
    return this.data.country;
  }

  /**
   * Get the phone number
   * @param {string}
   */
  get phoneNumber() {
    return this.data.phoneNumber;
  }

  /**
   * Get the timezone of the lead's submitted location
   * @return {string}
   */
  get timezone() {
    return this.data.timezone;
  }

  /**
   * Get the notes of the lead added by coach or IS
   * @return {string}
   */
  get notes() {
    return this.data.notes;
  }

  /**
   * Get the removed status
   * @return {boolean}
   */
  get removed() {
    return !!this.data.removed;
  }

  /**
   * Get the assigned coach's ID
   * @return {string}
   */
  get coach() {
    return this.data.coach;
  }

  /**
   * Get the last contacted time stamp
   * @return {Date|string}
   */
  get lastContactedAt() {
    return this.data.lastContactedAt || '';
  }

  /**
   * Get the user name of the agent who last contacted the lead
   * @return {string}
   */
  get lastContactedBy() {
    return this.data.lastContactedBy || '';
  }

  /**
   * Get the user ID of the agent who last contacted the lead
   * @return {string}
   */
  get lastContactedByUserId() {
    return this.data.lastContactedByUserId || '';
  }

  /**
   * Get the message provider used last time to contact the lead
   * @return {string}
   */
  get lastContactedMsgProvider() {
    return this.data.lastContactedMsgProvider || '';
  }

  /**
   * Get the user name of the server last message sent to the lead
   * @return {string}
   */
  get lastSendAs() {
    return this.data.lastSendAs || '';
  }

  /**
   * Get the user ID of the server last message sent to the lead
   * @return {string}
   */
  get lastSendAsUserId() {
    return this.data.lastSendAsUserId || '';
  }

  /**
   * Get the server contact number last message sent of the lead
   * @return {string}
   */
  get msgServerContactNo() {
    return this.data.msgServerContactNo || '';
  }

  /**
   * Get the source of lead form found
   * @return {string}
   */
  get source() {
    return this.data.answers.find((answerData) => answerData.custom_key === 'source')?.value || '-';
  }

  /**
   * If lead didn't show up for a scheduled call or not
   * @return {boolean}
   */
  get noShow() {
    return !!this.data.noShow;
  }

  /**
   * Update lead as a no show
   * @return {Promise<void>}
   */
  async setNoShow() {
    await this.updateFields({
      noShow: true,
    });
  }

  /**
   * Updates the state and sales stage of a lead based on the provided sales stage.
   * It also optionally adds an item to the lead's history if a 'userId' and 'userName' is provided.

   * @param {string} salesStage The sales stage of the lead
   * @param {Object} opts Optional parameters for setting the state
   * @param {string} opts.userId The user ID of the user setting the state
   * @param {string} opts.userName The user name of the user setting the state
   * @param {string} opts.reason The reason for the sales stage change
   * @return {Promise<void>}
   */
  setSalesStage(salesStage, {
    userId = '',
    userName = '',
    reason = '',
  } = {}) {
    let newLeadState;
    switch (salesStage) {
      case SalesStage.NEW_LEAD:
        newLeadState = LeadState.NEW;
        break;
      case SalesStage.UNQUALIFIED:
        newLeadState = LeadState.UNQUALIFIED;
        break;
      case SalesStage.LOST:
        newLeadState = LeadState.LOST;
        break;
      case SalesStage.WON:
        newLeadState = LeadState.WON;
        break;
      case SalesStage.PENDING:
        newLeadState = LeadState.PENDING;
        break;
      default:
        newLeadState = LeadState.IS_CONTACTED;
    }

    this.updateFields({
      salesStage,
      state: newLeadState,
    });

    if (userId && userName) {
      this.addItemToHistory({
        reason,
        value: salesStage,
        activityType: LeadHistoryType.SALES_STAGE_CHANGE,
        createdBy: userId,
        createdByUserName: userName,
      });
    }
  }

  /**
   * Updates the state and sales stage of a lead based on the provided state.
   * It also optionally adds an item to the lead's history if a 'userId' and 'userName' is provided.

   * @param {string} state The state of the lead
   * @param {Object} opts Optional parameters for setting the state
   * @param {string} opts.userId The user ID of the user setting the state
   * @param {string} opts.userName The user name of the user setting the state
   * @return {Promise<void>}
   */
  setState(state, {
    userId = '',
    userName = '',
  } = {}) {
    let newSalesStage;
    switch (state) {
      case LeadState.NEW:
        newSalesStage = SalesStage.NEW_LEAD;
        break;
      case LeadState.UNQUALIFIED:
        newSalesStage = SalesStage.UNQUALIFIED;
        break;
      case LeadState.LOST:
        newSalesStage = SalesStage.LOST;
        break;
      case LeadState.WON:
        newSalesStage = SalesStage.WON;
        break;
      default:
        newSalesStage = SalesStage.PENDING;
    }
    this.updateFields({
      state,
      salesStage: newSalesStage,
    });

    if (userId && userName) {
      this.addItemToHistory({
        value: state,
        activityType: LeadHistoryType.STATE_CHANGE,
        createdBy: userId,
        createdByUserName: userName,
      });
    }
  }

  /**
   * Add an item to the lead history based on the provided item object
   * It also updates the last contacted data of the lead if the activity type is MSG.

   * @param {Object} item The item to add to the lead history
   * @param {Object|string} item.value The value of the item
   * @param {string} item.reason The reason for the add item
   * @param {string} item.activityType The type of activity
   * @param {string} item.createdBy The user ID of the user who created the item
   * @param {string} item.createdByUserName The user name of the user who created the item
   * @param {Date} item.createdAt The date and time the item was created
   * @return {Promise<void>}
   */
  async addItemToHistory({
    value,
    reason,
    activityType,
    createdBy,
    createdByUserName,
    createdAt,
  }) {
    const item = {
      leadId: this.id,
      createdAt: createdAt || new Date(),
      createdBy,
      createdByUserName,
      activityType,
      value,
      coachId: this.coach,
    };

    if (reason) {
      item.reason = reason;
    }
    await LeadHistory.addLeadHistoryItem(item);

    // Add last contact data to lead doc
    if (activityType === LeadHistoryType.MSG) {
      await this.updateFields({
        lastContactedAt: new Date(),
        lastContactedBy: createdByUserName,
        lastContactedByUserId: createdBy,
        lastContactedMsgProvider: value.msgProvider,
        ...(value.sendAs && {
          lastSendAs: value.sendAsUserName,
          lastSendAsUserId: value.sendAs,
        }),
      });
    }
  }

  /**
   * Set the notes
   * @param {string} notes The notes for the lead
   * @return {Promise<void>}
   */
  setNotes(notes) {
    this.updateFields({
      notes,
    });
  }

  /**
   * Set the lead's removed status
   * @param {boolean} removed The removed status of the lead
   * @return {Promise<void>}
   */
  setRemoved(removed) {
    this.updateFields({
      removed,
      removedAt: new Date(),
    });
  }

  /**
   * Get the the historical records associated with the lead.
   * It includes lead's progression, interactions, and modifications over time.
   * @returns {Promise<Array>} The history documents for the lead.
   */
  async getHistory() {
    return LeadHistory.getLeadHistory(this.id);
  }

  /**
   * Get the lead by the specified email

   * @param {string} email The email of the lead
   * @returns {Promise<Lead|void>} The lead document if it exists, or else null.
   */
  static async getLeadByEmail(email) {
    const leadsCollection = new Collection(Collections.LEADS, {
      createDocument: ({ id }, opts) => new Lead(id, opts),
      query: (ref) => ref
        .where('email', '==', email)
        .limit(1),
    });
    await leadsCollection.fetch();
    return leadsCollection.hasDocs ? leadsCollection.docs[0] : null;
  }

  /**
   * Get all the leads for the specified list of coaches and from the specified date.

   * @param {Array} [coachesList = []] The list of coach IDs
   * @param {Date} fromDate The date from which to fetch the leads
   * @returns {Observable<Collection<Lead>>}
   */
  static async getAllLeadsByCoachList(coachesList = [], fromDate) {
    // Make sure we process an array of IDs.
    const coachIds = [...coachesList];

    /*
      Fetch all the leads for the specified coaches. We control the sales stage for the lead, so that we don't get
      leads we are not going to use (like disqualified leads).
    */
    const queriesPromises = () => coachIds.map((coachId) => (
      new Collection(Collections.LEADS, {
        createDocument: ({ id }, opts) => new Lead(id, { ...opts, disableObserverRefCount: true }),
        query: (ref) => {
          let query = ref
            .where('coach', '==', coachId)
            .where('salesStage', 'in', [
              SalesStage.NEW_LEAD,
              SalesStage.INVALID_CONTACT,
              SalesStage.FIRST_MESSAGE,
              SalesStage.SECOND_MESSAGE,
              SalesStage.THIRD_MESSAGE,
              SalesStage.IN_DIALOGUE,
              SalesStage.PENDING,
              SalesStage.DUPLICATE,
              SalesStage.CALENDLY_SCHEDULED,
            ]);
          if (fromDate) {
            query = query.where('submitDate', '>=', fromDate);
          }
          return query;
        },
      }).fetch()
    ));

    /*
      Wait for the queries to complete before returning the observable.= value. The components using this property
      will have to use observer correctly to get all the data.
    */
    const leadsCollections = await Promise.all(queriesPromises());

    return leadsCollections;
  }

  /**
   * Get all the leads for the specified role
   * @param {AssignmentRole} [role = IS] role The role for which to fetch the leads.
   * @returns {Observable<Collection<Lead>>}
   */
  static async getAllLeadsForRole(role = AssignmentRole.INSIDE_SALES) {
    // Get all the assignments for the specified role.
    const assignments = await InternalAssignment.getAllCurrentAssignmentsByRole(role);

    // Extract the list of coaches from the assignments. We are going to retrieve the leads for these coaches.
    const coaches = new Set();
    assignments.docs.forEach(({ coach }) => {
      coaches.add(coach);
    });

    return Lead.getAllLeadsByCoachList(coaches);
  }

  /**
   * Get the lead by the specified lead id

   * @param {string} id The lead id
   * @returns {Promise<Lead|void>} The lead document if it exists, or else null.
   */
  static async getLeadById(id) {
    const LeadDoc = new Lead(id);
    await LeadDoc.init();
    return LeadDoc.exists ? LeadDoc : null;
  }
}

export default Lead;
export {
  LeadState,
  SalesStage,
  InitialLeadState,
  AllLeadStates,
  AllSalesStages,
  ContactProvider,
  LocalContactProvider,
  LeadHistoryType,
  FinalStatusSalesStages,
};
