import styled from 'styled-components';

import { ReactComponent as SaveIcon } from '../../../../../../../assets/icons/v2/save.svg';

const StyledSaveIcon = styled(SaveIcon)`
  margin-right: 5px;
`;

export {
  StyledSaveIcon,
};
