import React, {
  useContext,
  useState,
  useEffect,
  useCallback,
  useMemo,
} from 'react';

import UserContext from '../../../../../../../context/UserContext';
import LoadingPage from '../../../../../../../components/LoadingPage';
import { StyledTextField } from '../../../../../../../components/Inputs';
import Button from '../../../../../../../components/v2/Button';
import useComponentMounted from '../../../../../../../hooks/useComponentMounted';
import { CoachingActivity } from '../../../../../../../utils/log';
import useLogger from '../../../../../../../hooks/useLogger';
import useToast from '../../../../../../hooks/useToast';
import CoachConfig, { LeadConfigOptions } from '../../../../../../Model/CoachConfig';
import RadioButtonGroup from '../../../../../../components/RadioButtonGroup';
import { SettingOption } from '../utils';
import {
  SettingContainer as Container,
  HeaderContainer,
  SettingsActionContainer,
  TitleContainer,
  Title,
  SubTitle,
  ActionLabel,
} from '../styles';
import {
  StyledSaveIcon,
} from './styles';
import texts from './texts.json';

const InboundMsgSettings = () => {
  const { userId: coachId } = useContext(UserContext);

  const isComponentMountedRef = useComponentMounted();
  const { logCoachingActivity } = useLogger();
  const { showToast } = useToast();

  const [coachConfig, setCoachConfig] = useState(null);
  const [leadSettings, setLeadSettings] = useState({});
  const [isReady, setIsReady] = useState(false);

  const isContactNoError = useMemo(() => (
    leadSettings[LeadConfigOptions.IS_TEXT_BACK_ENABLED] && !leadSettings[LeadConfigOptions.CONTACT_NO]
  ), [leadSettings]);

  const isEmailError = useMemo(() => (
    leadSettings[LeadConfigOptions.IS_EMAIL_RESPONSE_ENABLED] && !leadSettings[LeadConfigOptions.EMAIL_ADDRESS]
  ), [leadSettings]);

  useEffect(() => {
    const init = async () => {
      const coachConfigDoc = await CoachConfig.getCoachConfigByCoachId(coachId);
      if (isComponentMountedRef.current) {
        setCoachConfig(coachConfigDoc);
        setIsReady(true);
      }
    };
    if (coachId) {
      init();
    }
  }, [
    coachId,
    isComponentMountedRef,
  ]);

  useEffect(() => {
    if (coachConfig) {
      const {
        isTextBackEnabled,
        isEmailResponseEnabled,
        coachEmailAddress,
        coachContactNumber,
      } = coachConfig;

      setLeadSettings({
        [LeadConfigOptions.IS_TEXT_BACK_ENABLED]: isTextBackEnabled,
        [LeadConfigOptions.IS_EMAIL_RESPONSE_ENABLED]: isEmailResponseEnabled,
        [LeadConfigOptions.CONTACT_NO]: coachContactNumber,
        [LeadConfigOptions.EMAIL_ADDRESS]: coachEmailAddress,
      });
    }
  }, [coachConfig]);

  const handleSave = useCallback(async () => {
    try {
      await coachConfig.updateLeadConfig(leadSettings);
      logCoachingActivity(CoachingActivity.UPDATED_INBOUND_MESSAGE_SETTINGS);
      if (isComponentMountedRef.current) {
        showToast(texts.messageSettingSaveSuccessful);
      }
    } catch (error) {
      showToast(texts.messageSettingSaveFailed, { error: true });
    }
  }, [
    leadSettings,
    coachConfig,
    isComponentMountedRef,
    showToast,
    logCoachingActivity,
  ]);

  if (!isReady) {
    return <LoadingPage />;
  }

  return (
    <Container>
      <HeaderContainer>
        <TitleContainer>
          <Title>{texts.inboundMessaging}</Title>
          <SubTitle>{texts.inboundMessagingDescription}</SubTitle>
        </TitleContainer>
      </HeaderContainer>
      <SettingsActionContainer>
        <ActionLabel>{texts.inboundMessageSetting.textResponse}</ActionLabel>
        <RadioButtonGroup
          options={[
            {
              label: texts.enabled,
              value: SettingOption.ENABLED,
            },
            {
              label: texts.disabled,
              value: SettingOption.DISABLED,
            },
          ]}
          selectedOption={
            leadSettings[LeadConfigOptions.IS_TEXT_BACK_ENABLED] ? SettingOption.ENABLED : SettingOption.DISABLED
          }
          onOptionChange={(value) => setLeadSettings((prevState) => ({
            ...prevState,
            [LeadConfigOptions.IS_TEXT_BACK_ENABLED]: value === SettingOption.ENABLED,
          }))}
        />
      </SettingsActionContainer>
      <SettingsActionContainer>
        <ActionLabel>{texts.inboundMessageSetting.phoneNumber}</ActionLabel>
        <StyledTextField
          value={leadSettings[LeadConfigOptions.CONTACT_NO]}
          onChange={(event) => setLeadSettings((prevState) => ({
            ...prevState,
            [LeadConfigOptions.CONTACT_NO]: event.target.value,
          }))}
          placeholder={texts.contactNoPlaceholderText}
          error={isContactNoError}
          helperText={isContactNoError && texts.validation.required}
        />
      </SettingsActionContainer>
      <SettingsActionContainer>
        <ActionLabel>{texts.inboundMessageSetting.emailResponse}</ActionLabel>
        <RadioButtonGroup
          options={[
            {
              label: texts.enabled,
              value: SettingOption.ENABLED,
            },
            {
              label: texts.disabled,
              value: SettingOption.DISABLED,
            },
          ]}
          selectedOption={
            leadSettings[LeadConfigOptions.IS_EMAIL_RESPONSE_ENABLED] ? SettingOption.ENABLED : SettingOption.DISABLED
          }
          onOptionChange={(value) => setLeadSettings((prevState) => ({
            ...prevState,
            [LeadConfigOptions.IS_EMAIL_RESPONSE_ENABLED]: value === SettingOption.ENABLED,
          }))}
        />
      </SettingsActionContainer>
      <SettingsActionContainer>
        <ActionLabel>{texts.inboundMessageSetting.emailAddress}</ActionLabel>
        <StyledTextField
          value={leadSettings[LeadConfigOptions.EMAIL_ADDRESS]}
          onChange={(event) => setLeadSettings((prevState) => ({
            ...prevState,
            [LeadConfigOptions.EMAIL_ADDRESS]: event.target.value,
          }))}
          placeholder={texts.emailPlaceholderText}
          error={isEmailError}
          helperText={isEmailError && texts.validation.required}
        />
      </SettingsActionContainer>
      <Button
        onClick={handleSave}
        disabled={isContactNoError || isEmailError}
      >
        <StyledSaveIcon />
        {texts.save}
      </Button>
    </Container>
  );
};

export default InboundMsgSettings;
