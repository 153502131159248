import React, {
  useState,
  useContext,
  useMemo,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import format from 'string-template';
import { forEachSeries } from 'p-iteration';
import { TextField } from '@mui/material';
import { CheckCircleOutline } from '@mui/icons-material';

import ExternalCoachContext from '../../../context/ExternalCoachContext';
import useComponentMounted from '../../../../hooks/useComponentMounted';
import LoadingOverlay from '../../LoadingOverlay';
import WorkoutAssignment from '../../../../Model/WorkoutAssignment';
import Workout from '../../../../Model/Workout';
import DialogRoundedModal from '../../../../components/DialogRoundedModal';
import { SaveButton, CancelButton } from '../../../../components/Button/ActionButtons';
import AssignmentRepeatInput from '../AssignmentRepeatInput';
import useLogger from '../../../../hooks/useLogger';
import { CoachingActivity } from '../../../../utils/log';
import useUserDoc from '../../../hooks/useUserDoc';
import useUpdateInteraction from '../../../hooks/useUpdateInteraction';
import useToast from '../../../hooks/useToast';
import { WorkoutAssignmentCode, validateActivities } from '../../../../utils/workout';
import { DateFormat, getDatesBetween } from '../../../../utils/date';

import {
  ColumnContainer,
  EquipmentChip,
  RowContainer,
  StyledAutocomplete,
  StyledOption,
  TimeChip,
  StyledOptionSubContent,
  StyledCheckBoxLabel,
  CheckBoxContainer,
  StyledCheckBox,
  RepeatDay,
  DaysContainer,
  SettingSection,
  StyledInputLabel,
  RepeatContainer,
} from './styles';
import texts from './texts.json';

const WorkoutAssignmentModal = ({
  showModal,
  onClose,
  clientId,
  selectedDate,
}) => {
  const [coachWorkoutCollection, setCoachWorkoutCollection] = useState({ docs: [] });
  const [publicWorkoutCollection, setPublicWorkoutCollection] = useState({ docs: [] });
  const [selectedWorkout, setSelectedWorkout] = useState(null);
  const [selectedDays, setSelectedDays] = useState([]);
  const [showLoading, setShowLoading] = useState(true);
  const [isKeepWindowOpen, setIsKeepWindowOpen] = useState(false);
  const [shouldRepeatWorkout, setShouldRepeatWorkout] = useState(false);
  const [assignmentRepetitionWeeks, setAssignmentRepetitionWeeks] = useState(1);

  const { externalCoachDoc: { id: coachId } } = useContext(ExternalCoachContext);
  const {
    isReady: isClientDocReady,
    userDoc: clientDoc,
  } = useUserDoc(clientId);
  const { updateLastInteraction } = useUpdateInteraction(clientId);
  const isComponentMountedRef = useComponentMounted();
  const { showToast } = useToast();
  const { logCoachingActivity } = useLogger();

  useEffect(() => {
    const loadWorkouts = async () => {
      const coachWorkoutCol = await Workout.getWorkoutsByCoach(coachId);
      const publicWorkoutCol = await Workout.getPublicWorkouts();
      if (isComponentMountedRef.current) {
        setCoachWorkoutCollection(coachWorkoutCol);
        setPublicWorkoutCollection(publicWorkoutCol);
        setShowLoading(false);
      }
    };
    loadWorkouts();
  }, [coachId, isComponentMountedRef]);

  const workoutOptions = useMemo(() => [
    ...coachWorkoutCollection.docs,
    ...publicWorkoutCollection.docs,
  ].map((workout) => ({
    id: workout.id,
    label: workout.name,
    equipment: workout.equipmentList ? workout.equipmentList.join(', ') : '',
    workout,
  })), [coachWorkoutCollection, publicWorkoutCollection]);

  const endDate = useMemo(() => moment(selectedDate).add(assignmentRepetitionWeeks, 'weeks')
    .format(DateFormat.SHORT_MONTH_DAY_COMMA_YEAR_FORMAT), [
    selectedDate,
    assignmentRepetitionWeeks,
  ]);

  const onAssignWorkout = async () => {
    const validationResult = validateActivities(selectedWorkout.workout, true);
    if (validationResult === WorkoutAssignmentCode.VALID_WORKOUT) {
      if (shouldRepeatWorkout && (selectedDays.length === 0)) {
        showToast(texts.validations.selectRepeatDays, { warning: true });
        return;
      }
      const allDates = getDatesBetween(selectedDate, endDate, selectedDays);
      const selectedAllDates = shouldRepeatWorkout && (allDates.length > 0) ? allDates : [selectedDate];
      setShowLoading(true);
      await forEachSeries(selectedAllDates, async (date) => {
        await WorkoutAssignment.assignWorkout(clientDoc, selectedWorkout.workout, {
          startDate: moment.utc(date).startOf('day'),
          endDate: moment.utc(date).endOf('day'),
          coachId,
        });
      });
      // Update user's last interaction with the logged in user info
      await updateLastInteraction();

      logCoachingActivity(CoachingActivity.ASSIGNED_WORKOUT, {
        workoutId: selectedWorkout.workout.id,
        clientId: clientDoc.id,
      });
      showToast(texts.workoutAssigned);
      if (isComponentMountedRef.current) {
        setShowLoading(false);
        setSelectedWorkout(null);
        setSelectedDays([]);
        setAssignmentRepetitionWeeks(1);
        setShouldRepeatWorkout(false);
        if (!isKeepWindowOpen) {
          onClose();
        }
      }
    } else {
      showToast(validationResult, { warning: true });
    }
  };

  const handleDaysSelect = (day) => {
    if (selectedDays.includes(day)) {
      setSelectedDays(selectedDays.filter((selectedDay) => selectedDay !== day));
    } else {
      setSelectedDays([...selectedDays, day]);
    }
  };

  const handleCheckedRepeatWorkout = () => {
    setShouldRepeatWorkout((prev) => !prev);
    setSelectedDays([]);
    setAssignmentRepetitionWeeks(1);
  };

  return (
    <DialogRoundedModal
      title={texts.title}
      description={selectedDate}
      fullWidth
      maxWidth="sm"
      actionButtons={(
        <ColumnContainer>
          <CheckBoxContainer>
            <StyledCheckBox
              onChange={() => setIsKeepWindowOpen((prev) => !prev)}
              checked={isKeepWindowOpen}
              size="small"
            />
            <StyledCheckBoxLabel>{texts.keepWindowOpen}</StyledCheckBoxLabel>
          </CheckBoxContainer>
          <RowContainer>
            <SaveButton
              disabled={!selectedWorkout}
              icon={<CheckCircleOutline />}
              onClick={onAssignWorkout}
            >
              {texts.assignWorkout}
            </SaveButton>
            <CancelButton onClick={onClose}>Cancel</CancelButton>
          </RowContainer>
        </ColumnContainer>
      )}
      open={showModal}
      onClose={onClose}
    >
      <StyledAutocomplete
        disablePortal
        value={selectedWorkout}
        options={workoutOptions}
        renderInput={(params) => <TextField {...params} label="Select Workout" />}
        onChange={(event, value) => setSelectedWorkout(value)}
        renderOption={(props, {
          id,
          label,
          equipment,
          workout,
        }) => (
          <StyledOption {...props} key={id} className="option-list">
            {label}
            <StyledOptionSubContent>
              {!!equipment && (
                <EquipmentChip label={format(texts.equipment, { equipment })} />
              )}
              {!!workout.estimatedDurationInMinutes && (
                <TimeChip label={format(texts.estDuration, { minutes: workout.estimatedDurationInMinutes })} />
              )}
            </StyledOptionSubContent>
          </StyledOption>
        )}
      />
      <RepeatContainer>
        {!!selectedWorkout && (
          <CheckBoxContainer>
            <StyledCheckBox
              onChange={handleCheckedRepeatWorkout}
              checked={shouldRepeatWorkout}
              size="small"
            />
            <StyledCheckBoxLabel>{texts.repeatWorkout}</StyledCheckBoxLabel>
          </CheckBoxContainer>
        )}
        {!!selectedWorkout && shouldRepeatWorkout && (
          <>
            <SettingSection>
              <StyledInputLabel>{texts.repeat}</StyledInputLabel>
              <DaysContainer>
                {moment.weekdaysShort().map((day) => (
                  <RepeatDay
                    key={day}
                    onClick={() => handleDaysSelect(day)}
                    isSelected={selectedDays.includes(day)}
                  >
                    {day}
                  </RepeatDay>
                ))}
              </DaysContainer>
            </SettingSection>
            <AssignmentRepeatInput
              helperText={texts.repeatHelperText}
              value={assignmentRepetitionWeeks}
              onChange={setAssignmentRepetitionWeeks}
              startDate={selectedDate}
              endDate={endDate}
            />
          </>
        )}
      </RepeatContainer>
      <LoadingOverlay isLoading={showLoading || !isClientDocReady} />
    </DialogRoundedModal>
  );
};

WorkoutAssignmentModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  clientId: PropTypes.string.isRequired,
  selectedDate: PropTypes.string.isRequired,
};

export default WorkoutAssignmentModal;
