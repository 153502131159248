import Collections from '../../utils/collections';

import BaseDocument from '../../Model/BaseDocument';

/**
 * lead configuration option attribute names enum
 */
const LeadConfigOptions = {
  IS_EMAIL_RESPONSE_ENABLED: 'isEmailResponseEnabled',
  IS_TEXT_BACK_ENABLED: 'isTextBackEnabled',
  CONTACT_NO: 'contactNo',
  EMAIL_ADDRESS: 'emailAddress',
};

const orderType = {
  ASC: 'ASC',
  DESC: 'DESC',
};

/**
 * Options of activity feed auto archival setting
 */
const AutoArchiveDays = {
  TWO_WEEKS: 14,
  ONE_MONTH: 30,
  TWO_MONTHS: 60,
  THREE_MONTHS: 90,
};

/**
 * class representing configuration attributes of a coach
 *
 * @class CoachConfig
 * @extends BaseDocument
 */
class CoachConfig extends BaseDocument {
  constructor(id, opts) {
    super(`${Collections.COACH_CONFIG}/${id}`, opts);
  }

  /**
   * Get coach's activity feed configurations
   * @return {object}
   * @property {object} defaultOrder  - Default order of activity feed
   */
  get activityFeedConfigs() {
    return this.data?.activityFeedConfigs;
  }

  /**
   * Get default order of each attribute of activity feed
   * @return {object}
   * @property {string} attributeName - attribute name of default order
   * @property {string} order - Value of order (ASC/DESC)
   */
  get activityFeedDefaultOrder() {
    return this.activityFeedConfigs?.defaultOrder;
  }

  /**
   * Get attribute name of default order set
   * @return {string}
   */
  get activityFeedDefaultOrderAttributeName() {
    return this.activityFeedDefaultOrder?.attributeName;
  }

  /**
   * Get default order of attribute (ASC/DESC)
   * @return {string}
   */
  get activityFeedDefaultOrderValue() {
    return this.activityFeedDefaultOrder?.order || orderType.ASC;
  }

  /**
   * Number of days coach activities will be considered to be active before automatically removing them.
   * @return {number}
   */
  get daysToAutoArchive() {
    return this.data.activityFeedConfigs?.daysToAutoArchive || AutoArchiveDays.TWO_WEEKS;
  }

  /**
   * Get coach's lead configurations
   * @return {object}
   */
  get leadConfigs() {
    return this.data.leadConfigs || {};
  }

  /**
   * Is text responding enabled for leads or not
   * @return {boolean}
   */
  get isTextBackEnabled() {
    return !!this.leadConfigs?.isTextBackEnabled;
  }

  /**
   * Is email responding enabled for leads or not
   * @return {boolean}
   */
  get isEmailResponseEnabled() {
    return !!this.leadConfigs?.isEmailResponseEnabled;
  }

  /**
   * Email address of the coach configured for leads.
   * @return {string}
   */
  get coachEmailAddress() {
    return this.leadConfigs?.emailAddress || '';
  }

  /**
   * Phone number of the coach configured for leads.
   * This is only available for non IS coaches, as IS enabled coaches are handled with internal numbers.
   * @return {string}
   */
  get coachContactNumber() {
    return this.leadConfigs?.contactNo || '';
  }

  // Method

  /**
   * Get coachConfig by coachId
   * @param {string} coachId - The coach id
   * @returns {object} - coachConfig doc
   */
  static async getCoachConfigByCoachId(coachId) {
    const coachConfigDoc = new CoachConfig(coachId);
    await coachConfigDoc.init();

    return coachConfigDoc;
  }

  /**
   * Update default order of activity feed based on the given attribute and value
   *
   * @param {string} property Which attribute going to be update
   * @param {string} order Order (ASC/DESC)
   * @return {Promise<void>}
   */
  setDefaultOrder = async (property, order) => {
    await this.set({
      activityFeedConfigs: {
        defaultOrder: {
          attributeName: property,
          order,
        },
      },
    }, { merge: true });
  };

  /**
   * Update activity feed archival time
   *
   * @param {number} daysToAutoArchive - Number of days coach activities will be regarded active
   *                                     before automatically removing them
   * @return {Promise<void>}
   */
  setDaysToAutoArchive = async (daysToAutoArchive) => {
    await this.set({
      activityFeedConfigs: {
        daysToAutoArchive,
      },
    }, { merge: true });
  }

  /**
   * Update coach's lead config settings
   *
   * @param {object} settings - object of attributes and their values to update
   * @return {Promise<void>}
   */
  updateLeadConfig = async (settings) => {
    await this.set({
      leadConfigs: {
        ...settings,
      },
    }, { merge: true });
  };
}

export default CoachConfig;
export {
  LeadConfigOptions,
  orderType,
  AutoArchiveDays,
};
